#footer a.item {
  font-family: 'FFQuadraatSansWebPro', sans-serif;
}

#footer .footer {
  padding: 0rem 2rem 0.8rem 2rem;
}

#footer {
  .echamp-footer-text {
    padding: 0 15%;
    font-family: 'FFQuadraatSansWebPro';
  }

  a.social {
    display: inline-block;
    width: 2.5em;
    height: 2.5em;
    margin: 0 5px;
    background-image: url(images/social_sprite2.svg);
    background-size: 15em 2.5em;
  }

  a.social-yt {
    background-position: 0 0;
  }

  a.social-vm {
    background-position: -2.5em 0;
  }

  a.social-li {
    background-position: -5em 0;
  }

  a.social-tw {
    background-position: -7.5em 0;
  }
}
