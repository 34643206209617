body.siteroot .header-wrapper .header {
  background: url('images/banners/echamp_home3.jpg');

  .container.layout {
    //height: 288px;
    height: 204px;
  }

  .logo {
    display: flex;

    > a {
      > img {
        width: 240px;
      }
    }
  }

  .logoText {
    display: none;
  }
}

.header-wrapper .header {
  .tools-wrapper {
    background-color: $echampColor;

    .tools a {
      color: white;
    }

    .tools .anontools a {
      color: white;
    }

    a[aria-label='register'] {
      display: none;
    }
  }

  .logo-nav-wrapper {
    align-items: center;
    padding-top: 10px;

    .logo {
      flex: 0.5 1 0;
      padding-left: 10px;
      justify-self: top;

      > a {
        > img {
          width: 100px;
          padding-top: 5px;
          float: left;
        }
      }
    }
  }

  .logoText {
    display: block;
    width: 14em;
    flex: 1.3 0.5 0;
    align-self: left;
    padding-top: 30px;
    margin-left: 10px;
    float: right;
    font-size: 70%;
    @media only screen and (max-width: 1100px) {
      font-size: 60%;
    }
  }

  .mobile-nav {
    .hamburger-wrapper {
      button {
        margin-top: -20px;
      }
    }
  }

  .search-wrapper {
    flex: 0.5 1 0;
    padding-top: 10px;
  }
}

.navigation {
  height: 64px;
  font-family: $custom-sans-fontname;

  @media only screen and (max-width: $tablet-breakpoint) {
    display: none !important;
  }

  .item {
    position: relative;
    color: $black;
    font-family: $custom-sans-fontname;
    font-size: 17px;
    font-weight: 600;
    line-height: 24px;
    text-transform: initial;
    word-spacing: -0.5px;
    @media only screen and (max-width: 1111px) {
      font-size: 15px;
    }
    @media only screen and (max-width: 1100px) {
      font-size: 14px;
    }

    &.active::before,
    &:hover::before {
      position: absolute;
      // bottom: -49px;
      bottom: -30px;
      width: 100%;
      border-bottom: 9px solid $black;
      content: '';
    }
  }
}

body.section-news-and-events {
  #navigation a.item.active {
    background-color: $echampNews;
  }
}

body.section-echamp-resources {
  #navigation a.item.active {
    background-color: $echampResources;
  }
}

body.section-eu-policy-and-regulation {
  #navigation a.item.active {
    background-color: $echampPolicy;
  }
}

body.section-our-sector {
  #navigation a.item.active {
    background-color: $echampSector;
  }
}

body.section-inside-echamp {
  #navigation a.item.active {
    background-color: $echampInside;
  }
}
