@font-face {
  font-display: swap;
  font-family: 'FFQuadraatWebPro';
  font-style: normal;
  font-weight: 400;
  src: url('fonts/ffquadraatwebpro/2F2C77_0_0.eot');
  src:
    url('fonts/ffquadraatwebpro/2F2C77_0_0.eot?#iefix') format('embedded-opentype'),
    url('fonts/ffquadraatwebpro/2F2C77_0_0.woff2') format('woff2'),
    url('fonts/ffquadraatwebpro/2F2C77_0_0.woff') format('woff'),
    url('fonts/ffquadraatwebpro/2F2C77_0_0.ttf') format('truetype');
}

@font-face {
  /* font-family: 'FFQuadraatWebProItalic'; */
  font-display: swap;
  font-family: 'FFQuadraatWebPro';
  font-style: italic;
  font-weight: 400;
  src: url('fonts/ffquadraatwebpro/2F2C77_1_0.eot');
  src:
    url('fonts/ffquadraatwebpro/2F2C77_1_0.eot?#iefix') format('embedded-opentype'),
    url('fonts/ffquadraatwebpro/2F2C77_1_0.woff2') format('woff2'),
    url('fonts/ffquadraatwebpro/2F2C77_1_0.woff') format('woff'),
    url('fonts/ffquadraatwebpro/2F2C77_1_0.ttf') format('truetype');
}

@font-face {
  /* font-family: 'FFQuadraatWebProBold'; */
  font-display: swap;
  font-family: 'FFQuadraatWebPro';
  font-style: normal;
  font-weight: 700;
  src: url('fonts/ffquadraatwebpro/2F2C77_3_0.eot');
  src:
    url('fonts/ffquadraatwebpro/2F2C77_3_0.eot?#iefix') format('embedded-opentype'),
    url('fonts/ffquadraatwebpro/2F2C77_3_0.woff2') format('woff2'),
    url('fonts/ffquadraatwebpro/2F2C77_3_0.woff') format('woff'),
    url('fonts/ffquadraatwebpro/2F2C77_3_0.ttf') format('truetype');
}

@font-face {
  /* font-family: 'FFQuadraatSansWebProRegular'; */
  font-family: 'FFQuadraatSansWebPro';
  font-style: normal;
  font-weight: 400;
  src: url('fonts/ffquadraatwebpro/2F2C77_2_0.eot');
  src:
    url('fonts/ffquadraatwebpro/2F2C77_2_0.eot?#iefix') format('embedded-opentype'),
    url('fonts/ffquadraatwebpro/2F2C77_2_0.woff2') format('woff2'),
    url('fonts/ffquadraatwebpro/2F2C77_2_0.woff') format('woff'),
    url('fonts/ffquadraatwebpro/2F2C77_2_0.ttf') format('truetype');
}

@font-face {
  /* font-family: 'FFQuadraatSansWebProBold'; */
  font-family: 'FFQuadraatSansWebPro';
  font-style: normal;
  font-weight: 700;
  src: url('fonts/ffquadraatwebpro/2F2C77_4_0.eot');
  src:
    url('fonts/ffquadraatwebpro/2F2C77_4_0.eot?#iefix') format('embedded-opentype'),
    url('fonts/ffquadraatwebpro/2F2C77_4_0.woff2') format('woff2'),
    url('fonts/ffquadraatwebpro/2F2C77_4_0.woff') format('woff'),
    url('fonts/ffquadraatwebpro/2F2C77_4_0.ttf') format('truetype');
}

//underlines in text blocks with headers or bold/strong

.content-area {
  p > strong > a {
    color: $blue-for-grey-contrast !important;
    font-family: inherit;
    font-size: inherit;
    font-weight: inherit;
    text-decoration: underline;
  }

  h2 > a,
  h3 > a {
    color: $echampColor !important;
    font-family: inherit;
    font-size: inherit;
    font-weight: inherit;
    text-decoration: underline;
  }

  h2 > strong > p,
  h3 > strong > p {
    font-family: inherit;
    font-size: inherit;
    font-weight: inherit;
  }
  // comment
}
// Add/Edit views
#page-add,
#page-edit {
  .block-editor-description {
    @include narrow-container-width();
    @include adjustMarginsToEditContainer($narrow-container-width);
  }
}

.block.description .documentDescription {
  @include default-container-width();
  @include adjustMarginsToEditContainer($default-container-width);
}

.listing .listing-item .listing-body h3 {
  margin-top: 32px;
}

.documentDescription {
  font-weight: 700;
}

.content-area .container li {
  padding-bottom: 10px;
}

.content-area h1.documentFirstHeading {
  margin-bottom: 30px;
}

sub a {
  font-size: 13.5px;
}

strong a {
  font-weight: 800;
  text-decoration: underline;
}

h2,
h3 {
  margin-top: 30px;
  margin-bottom: 20px;
}

a .listing-body h2 {
  color: $echampColor !important;
  text-decoration: underline;
}
