// This theme extends the volto-light-theme
// it is injected

// :root {
//   --narrow-container-width: #{$_narrow-container-width};
//   --default-container-width: #{$_default-container-width};
//   --teaser-images-aspect-ratio: #{$teaser-images-aspect-ratio};
// }

:root {
  --narrow-container-width: 1100px;
  --default-container-width: 1100px;
}

@import 'root';
@import 'typography';
@import 'layout';
@import 'header';
@import 'footer';
@import 'grid';
@import 'listing';
@import 'blocks';
@import 'bgcolor-blocks-layout';
@import 'color-picker-widget';

@import 'content';

@import 'components/organisation-view.scss';
@import 'components/blockquote.scss';

#page-document .blocks-group-wrapper table {
  font-family: $custom-sans-fontname;
}

/* fix slate styling inside a grid block */

#page-document .blocks-group-wrapper .listing-table-title b {
  font-weight: 700;
}

#page-document .blocks-group-wrapper .column .slate h3 a {
  font-size: 24px;
  font-weight: 700;
}

#page-document .blocks-group-wrapper .column .slate h2 a {
  font-size: 30px;
  font-weight: 700;
}

/* menu tweaks */

/* disable main subsection title in menu dropdown */

.submenu > .submenu-inner > a.submenu-header {
  display: none;
}
