// Grid Block Overwrites\
.block.gridBlock {
  .block.listing {
    .items {
      margin-bottom: 0;
    }

    &.default {
      .listing-item {
        h2,
        h3 {
          @include text-heading-h4();
          margin-bottom: 20px !important;
        }
      }
    }
  }
}

.block.gridBlock {
  figure:after {
    display: none;
  }
}

.block.gridBlock .column .slate {
  --custom-main-font: 'FFQuadraatSansWebPro';
}

.block.gridBlock,
#page-add .block-editor-gridBlock,
#page-edit .block-editor-gridBlock {
  .slate {
    --custom-main-font: 'FFQuadraatSansWebPro';
  }
}
