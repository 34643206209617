.color-picker-widget {
  .button.transparent,
  .button.transparent.active,
  .button.outline-white,
  .button.outline-white.active {
    // background: linear-gradient(90deg, #fff 18px, $lightgreen 18px);
    background: radial-gradient(circle, darken($lightgreen, 10%) 40%, rgba(255, 255, 255, 1) 40%);
    //   #fff,
    //   #fff 10px,
    //   $lightgreen 10px,
    //   $lightgreen 20px
    // );
  }

  .button.green_white,
  .button.green_white.active,
  .button.outline-white,
  .button.outline-white.active {
    // background: repeating-linear-gradient(
    //   45deg,
    //   $lightgreen,
    //   $lightgreen 10px,
    //   #fff 10px,
    //   #fff 20px
    // );
    background: radial-gradient(circle, rgba(255, 255, 255, 1) 40%, darken($lightgreen, 10%) 40%);
  }

  .button.blue_white,
  .button.blue_white.active,
  .button.outline-white,
  .button.outline-white.active {
    // background: repeating-linear-gradient(
    //   45deg,
    //   $lightblue,
    //   $lightblue 10px,
    //   #fff 10px,
    //   #fff 20px
    // );
    background: radial-gradient(circle, rgba(255, 255, 255, 1) 40%, darken($lightblue, 10%) 40%);
  }

  .button.news,
  .button.news.active,
  .button.outline-white,
  .button.outline-white.active {
    // background: repeating-linear-gradient(
    //   45deg,
    //   $lightblue,
    //   $lightblue 10px,
    //   #fff 10px,
    //   #fff 20px
    // );
    background: radial-gradient(circle, rgba(255, 255, 255, 1) 40%, darken($echampNews, 10%) 40%);
  }

  .button.sector,
  .button.sector.active,
  .button.outline-white,
  .button.outline-white.active {
    // background: repeating-linear-gradient(
    //   45deg,
    //   $lightblue,
    //   $lightblue 10px,
    //   #fff 10px,
    //   #fff 20px
    // );
    background: radial-gradient(circle, rgba(255, 255, 255, 1) 40%, darken($echampSector, 10%) 40%);
  }

  .button.policy,
  .button.policy.active,
  .button.outline-white,
  .button.outline-white.active {
    // background: repeating-linear-gradient(
    //   45deg,
    //   $lightblue,
    //   $lightblue 10px,
    //   #fff 10px,
    //   #fff 20px
    // );
    background: radial-gradient(circle, rgba(255, 255, 255, 1) 40%, darken($echampPolicy, 10%) 40%);
  }

  .button.resources,
  .button.resources.active,
  .button.outline-white,
  .button.outline-white.active {
    // background: repeating-linear-gradient(
    //   45deg,
    //   $lightblue,
    //   $lightblue 10px,
    //   #fff 10px,
    //   #fff 20px
    // );
    background: radial-gradient(circle, rgba(255, 255, 255, 1) 40%, darken($echampResources, 10%) 40%);
  }

  .button.inside,
  .button.inside.active,
  .button.outline-white,
  .button.outline-white.active {
    // background: repeating-linear-gradient(
    //   45deg,
    //   $lightblue,
    //   $lightblue 10px,
    //   #fff 10px,
    //   #fff 20px
    // );
    background: radial-gradient(circle, rgba(255, 255, 255, 1) 40%, darken($echampInside, 10%) 40%);
  }
}
