#page-document .blocks-group-wrapper blockquote {
  width: 30%;
  padding-right: 20px;
  padding-left: 20px;
  border-left: 6px solid $echampColor;
  margin-left: 20px;
  float: right;
  font-family: $custom-sans-fontname;
  font-size: 110%;
  font-weight: 700;
}
