// Variables file
$white: #ffffff;
$lightblue: #e9f0f4;
$lightgreen: #ebf0e6;
$darkgreen: #809c66;
$darkergreen: #5f7e48;
$black: #000000;
$blue: #0066cc;
$darkblue: #023d6b;
$echampColor: #3f5164;

$echampNews: rgb(255, 198, 116);
$echampSector: rgb(179, 200, 230);
$echampPolicy: rgb(143, 223, 226);
$echampResources: rgb(255, 183, 174);
$echampInside: rgb(214, 227, 66);
$narrow-container-width: 1100px;
$default-container-width: 1100px;

$text-color: #00ff00;

$link-color: $darkblue;
$link-color-hover: $echampColor;

$custom-fontname: 'FFQuadraatWebPro' !default;
$custom-sans-fontname: 'FFQuadraatSansWebPro' !default;

$page-font-template: 'FFQuadraatWebPro', sans-serif !default;

// Image ratio
body {
  --image-aspect-ratio: 4/3;
}

// Mixins
@mixin green-button($bgcolor, $color) {
  display: flex;
  align-items: center;
  padding: 8px 20px;
  border: none;
  background-color: $bgcolor;
  color: $color;
  font-family: 'FFQuadraatWebPro';
  font-size: 18px;
  font-style: normal;
  font-weight: 700;
  line-height: 24px;
  text-align: center;
}

@mixin word-break() {
  -ms-hyphens: auto;
  -moz-hyphens: auto;
  -webkit-hyphens: auto;
  hyphens: auto;
  overflow-wrap: break-word;
  word-break: break-word;
  word-wrap: break-word;
}
