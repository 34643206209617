.view-wrapper.OrganisationView {
  @include default-container-width();
  @include adjustMarginsToContainer($default-container-width);
}

.view-wrapper.related-items {
  margin-bottom: 2em;
  @include default-container-width();
  @include adjustMarginsToContainer($default-container-width);
}

.section-sitemap .view-wrapper,
.section-search .content-area,
.contenttype-file .view-wrapper {
  @include default-container-width();
  @include adjustMarginsToContainer($default-container-width);
}

.contenttype-echamp\.organisation .view-wrapper .grid {
  @include narrow-container-width();
}

// When background is colored, add margin

@media only screen and (max-width: 1536px) {
  .blocks-group-wrapper {
    &.news,
    &.sector,
    &.policy,
    &.resources,
    &.inside {
      margin: 0 20px;
    }
  }
}
