.content-area .ui.container,
.content-area .a.container {
  a.external:after,
  a.external:after {
    display: none;
  }
}

.content-area #page-edit .ui.container,
.content-area .a.container {
  a.external:after,
  a.external:after {
    display: none;
    margin-top: -25px;
    margin-left: 3px;
  }
}
