.blocks-group-wrapper.green_white,
[class*='block-editor-'].has--backgroundColor--green_white {
  background-color: $lightgreen;
}

.blocks-group-wrapper.blue_white,
[class*='block-editor-'].has--backgroundColor--blue_white {
  background-color: $lightblue;
}

.blocks-group-wrapper.news,
[class*='block-editor-'].has--backgroundColor--news {
  background-color: $echampNews;
}

.blocks-group-wrapper.sector,
[class*='block-editor-'].has--backgroundColor--sector {
  background-color: $echampSector;
}

.blocks-group-wrapper.policy,
[class*='block-editor-'].has--backgroundColor--policy {
  background-color: $echampPolicy;
}

.blocks-group-wrapper.resources,
[class*='block-editor-'].has--backgroundColor--resources {
  background-color: $echampResources;
}

.blocks-group-wrapper.inside,
[class*='block-editor-'].has--backgroundColor--inside {
  background-color: $echampInside;
}
