// Links
a {
  color: $link-color;
  text-decoration: none;

  &:hover {
    color: $link-color-hover;
    text-decoration: none;
  }
}

body {
  color: #3f5164;
}
