// VLT overwrites

// Listing Block
.block.listing,
.column > .block.listing,
.block.search,
.block-editor-search {
  // Default variation
  a {
    h3 {
      color: $link-color;
      text-decoration: underline;

      &:hover {
        color: $link-color-hover;
        text-decoration: underline;
      }
    }
  }

  &.default {
    .headline {
      @include block-title();
    }

    .listing-item {
      a {
        .listing-body {
          h2 {
            color: $black;
            @include text-heading-h2();
          }

          p {
            margin-bottom: 0;
            @include body-text();
            text-decoration: none;
          }

          p:empty {
            display: none;
          }
        }
      }
    }
  }
  //table_dates variation
  &.table_dates,
  &.files {
    .headline {
      @include block-title();
    }

    .ui.table {
      @include default-container-width();
    }

    .listing-table-date {
      min-width: 150px;
    }

    .listing-table-title {
      min-width: 200px;
      text-decoration-line: underline;
    }

    .listing-table-description {
      font-family: 'FFQuadraatSansWebPro';
    }
  }
}

// Listing Block Overwrites
.block.listing.default,
.block.gridBlock .column .block.listing {
  .listing-item {
    padding-bottom: 25px !important;
    border-bottom: 1px solid $black;
    margin-bottom: 25px;

    a .listing-body,
    .listing-body {
      h2 {
        @include text-heading-h4();
        margin-bottom: 20px;
      }
    }
  }
}

// Listing Block inside Grid
.block.gridBlock .block.listing.default {
  .listing-item:first-of-type a .listing-body h2 {
    padding-top: 0;
  }
}

.gridBlock .block.listing {
  h3 {
    font-size: 1.15em;
  }
}

.listing-date {
  display: inline-block;
  padding: 2px;
  border: 1px solid #ccc;
  margin-bottom: 6px;
  background-color: #f6f6f6;
  text-align: center;

  &:after {
    content: ' ';
  }
}
