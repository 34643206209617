.column:has(div.has--innerBackgroundColor--news) {
  background: $echampNews;
}

div.has--innerBackgroundColor--news,
div.has--backgroundColor--news {
  background: $echampNews;
}

.column:has(div.has--innerBackgroundColor--sector) {
  background-color: $echampSector;
}

div.has--innerBackgroundColor--sector,
div.has--backgroundColor--sector {
  background-color: $echampSector;
}

.column:has(div.has--innerBackgroundColor--policy) {
  background-color: $echampPolicy;
}

div.has--innerBackgroundColor--policy,
div.has--backgroundColor--policy {
  background-color: $echampPolicy;
}

.column:has(div.has--innerBackgroundColor--resources) {
  background-color: $echampResources;
}

div.has--innerBackgroundColor--resources,
div.has--backgroundColor--resources {
  background-color: $echampResources;
}

.column:has(div.has--innerBackgroundColor--inside) {
  background-color: $echampInside;
}

div.has--innerBackgroundColor--inside,
div.has--backgroundColor--inside {
  background-color: $echampInside;
}
